<template>
  <div class="add-new-empoyee">
    <title-bar
      :submitBtn="editView"
      :submitBtnTitle="$t('messages.employeeUpdate')"

      :title="editView ? $t('messages.employeeUpdate') : $t('messages.employeeDetail')"
      @submitPressed="showModal"

      :thirdBtn="employee && editView"
      :thirdBtnTitle="$t('pdf.printCard')"
      @thirdPressed="printEmployeeCard"

      :secondaryBtn="employeeCard && editView"
      :secondaryBtnTitle="$t('pdf.deletePrintCard')"
      @secondaryPressed="deleteEmployeeCard"

    />

    <b-modal
        v-model="modalShow"
        :ok-title="$t('forms.submitChanges')"
        @ok="submitPressed()"
        @hide="$emit('clearAction')"
    >
        <div>
          <b-alert
              show
              class="mt-3"
              variant="danger"
          >{{ $t("forms.employeeSubmitChangesAlert") }}
          </b-alert>
        </div>
    </b-modal>

    <employee-form
      v-if="employee"
      :employeeObject="employee"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import EmployeeForm from "./components/EmployeeForm.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TitleBar,
    EmployeeForm,
  },
  data() {
    return {
      modalShow: false,
      employee: null,
      employeeCard: null,
      action: null,
      employeeList: null,
      editView: false,
      printPdf: {
        templateName: 'EMPLOYEE_CARD'
      },
    };
  },
  mixins: [ResourceUtils],
  created() {
    this.setMode();
    this.load();
  },
  methods: {
    load() {
      const id = this.$route.params.id;
      this.$Users.getResource({ id }).then((response) => {
        this.employee = response.data;
        this.employee.shiftSelect = {
          label: this.employee.shift.name,
          id: this.employee.shift["@id"],
        };
        if (this.employee.printStatus) {
          this.employee.printStatusSelect = {
            label: this.$helper.getEnumTranslation(
              "PRINT_STATUS",
              this.employee.printStatus,
              this.$i18n.locale
            ),
            id: this.employee.printStatus,
          };
        }
        if (this.employee.employeeType) {
          this.employee.typeSelect = {
            label: this.employee.employeeType.name,
            id: this.employee.employeeType["@id"],
          };
        }
        if (this.employee.job) {
          this.employee.jobSelect = {
            label: this.employee.job.name,
            id: this.employee.job["@id"],
          };
        }
        if (this.employee.department) {
          this.employee.departmentSelect = {
            label: this.employee.department.name,
            id: this.employee.department["@id"],
          };
        }
        if (this.employee.contract) {
          this.employee.contractSelect = {
            label: this.employee.contract.name,
            id: this.employee.contract["@id"],
          };
        }
        if (this.employee.status) {
          this.employee.statusSelect = {
            label: this.employee.status.name,
            id: this.employee.status["@id"],
          };
        }
        if (this.employee.tshirtSize) {
          this.employee.tshirtSizeSelect = {
            label: this.employee.tshirtSize.name,
            id: this.employee.tshirtSize["@id"],
          };
        }
        if (this.employee.education) {
          this.employee.educationSelect = {
            label: this.$helper.getEnumTranslation(
                "EDUCATION_LEVEL",
                this.employee.education,
                this.$i18n.locale
            ),
            id: this.employee.education,
          };
        }
        if (this.employee.itemCategory) {
          this.employee.itemCategorySelect = {
            label: this.$helper.getEnumTranslation(
                "ITEM_CATEGORY",
                this.employee.itemCategory,
                this.$i18n.locale
            ),
            id: this.employee.itemCategory,
          };
        }
        this.employee.itemCategoriesSelect = []
        if (this.employee.itemCategories && this.employee.itemCategories.length > 0) {
          this.employee.itemCategories.forEach((itemCategory) => {
            this.employee.itemCategoriesSelect.push({
              label: this.$helper.getEnumTranslation(
                  "ITEM_CATEGORY",
                  itemCategory,
                  this.$i18n.locale
              ),
              id: itemCategory,
            });
          })
        }
        this.setEmployeeCard()
      });
    },
    showModal() {
      this.modalShow = true;
    },
    setMode() {
      this.editView = this.$route.name === 'EditEmployee'
    },
    submitPressed() {
      this.action = "update";
    },
    setEmployeeCard() {
      const body = {
        name: `${this.printPdf.templateName.toLowerCase()}_${this.employee.id}`,
        templateName: this.printPdf.templateName,
        resource: this.employee["@id"],
      };

      this.$Pdfs
          .getResourceByUrl({ url: `/pdfs/${body.name}` })
          .then((response) => {
            this.employeeCard = response.data
          })
    },
    deleteEmployeeCard() {
      this.deleteByUrl(
          this.$Pdfs,
          `/pdfs/${this.printPdf.templateName.toLowerCase()}_${this.employee.id}`,
          this.$t("pdf.employeeCardDeleted"),
          null,
          () => {
            this.employeeCard = null
          }
      );
    },
    printEmployeeCard() {
      const body = {
        name: `${this.printPdf.templateName.toLowerCase()}_${this.employee.id}`,
        templateName: this.printPdf.templateName,
        resource: this.employee["@id"],
      };

      this.$Pdfs
          .getResourceByUrl({ url: `/pdfs/${body.name}` })
          .then((response) => {
            const link = document.createElement("a");
            link.href = response.data.notCachedUrl;
            link.target = "_blank";
            link.click();
          })
          .catch(() => {
            this.$Pdfs.createResource({ body }).then((response) => {
              if (response.status === 201) {
                this.employeeCard = response.data
                const link = document.createElement("a");
                link.href = response.data.notCachedUrl;
                link.target = "_blank";
                link.click();
              }
            });
          })
    },
  },
};
</script>
